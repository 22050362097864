import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useOfferContext } from '../../../fragments/OfferCardWidget/shared/context';
import { useApplicationContext } from '../../utils/applicationContext';
import { selectIsHidePhoneNumberExperiment } from 'shared/selectors/experiments/selectIsHidePhoneNumberExperiment';

export function useGetIsAuthenticationPopupEnabled(): boolean {
  const { config } = useApplicationContext();
  const {
    offer: { category, offerType, geo },
    agentType,
  } = useOfferContext();

  const isExperimentEnabled = useSelector(selectIsHidePhoneNumberExperiment);
  const isHomeowner = agentType === 'homeowner';

  const isHidingPhoneNumberEnabled = config.get<boolean>('BX.HidingPhoneNumber.Enabled') ?? false;
  const hidingPhoneNumberRegions = config.get<number[]>('BX.HidingPhoneNumber.Regions') ?? [];

  const regionId = useMemo(() => {
    return hidingPhoneNumberRegions.some(id => id === geo?.address[0].id);
  }, [geo]);

  return useMemo(
    () =>
      isHidingPhoneNumberEnabled &&
      isExperimentEnabled &&
      regionId &&
      isHomeowner &&
      offerType === 'flat' &&
      (category === 'flatSale' || category === 'flatRent' || category === 'dailyFlatRent'),
    [isHidingPhoneNumberEnabled, isExperimentEnabled, geo, isHomeowner, offerType, category],
  );
}
